import { SocialIcon } from 'react-social-icons';

function Footer() {
    return (
        <div className="items-center flex flex-col gap-6 text-black dark:text-white min-w-screen h-auto order-last mb-6 pt-10 font-philosopher" id="Serkan Demir's social media links">
            <div className="flex flex-row flex-wrap gap-5 justify-around items-center">
                <SocialIcon url="https://twitter.com/hatiyakodemir" style={{ height: 35, width: 35 }} bgColor="#A5B4FC" id="serkan-demir-twitter" />
                <SocialIcon url="https://github.com/demirserkan" style={{ height: 35, width: 35 }} bgColor="#A5B4FC" id="serkan-demir-github" />
                <SocialIcon url="https://www.linkedin.com/in/demirserkan" style={{ height: 35, width: 35 }} bgColor="#A5B4FC" id="serkan-demir-linkedin" />
                <SocialIcon url="https://www.instagram.com/serkandem.r" style={{ height: 35, width: 35 }} bgColor="#A5B4FC" id="serkan-demir-instagram" />
            </div>
            <p className="text-black dark:text-white text-opacity-50">Copyright © Serkan Demir. All rights reserved.</p>
        </div>
    );
}

export default Footer;