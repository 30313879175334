
import Contact from "./Contact";
import { Switch, Route, Link } from "react-router-dom";
import content from '../content/content';
import Typical from 'react-typical'
import { useEffect, useState } from "react";

function Home() {
    const [animated, setAnimated] = useState(false);
    useEffect(() => {
        setAnimated(true);
    }, [])
    return (
        <div className="min-h-screen flex items-center justify-center" id="serkan-demir-home-page">
            <div className="w-10/12 mx-auto flex flex-col md:flex-row-reverse items-center justify-between font-philosopher">
                <div className="w-screen md:w-2/5 pt-10 md:pt-0">
                    <img src={content.header.img} alt="Serkan Demir" className=" blur-2xl" />
                </div>
                <div className="text-white text-center md:text-left md:pt-0 pt-10">
                    <h2 className={(animated ? "" : "translate-y-10 opacity-0 ") + 'transform transition duration-2000 ease-in-out md:text-5xl text-3xl font-bold'}>
                        {content.header.text[0]}
                        <br />
                        {content.header.text[1]}
                    </h2>
                    <h1 className={(animated ? "" : "translate-y-10 opacity-0 ") + 'transform transition duration-2000 ease-in-out text-2xl font-bold text-gray-500'}>
                        {content.header.text[2]}
                        <Typical
                            steps={content.header.typical}
                            loop={Infinity}
                            className="inline-block"
                        />
                    </h1>
                    <Link to="/experience">
                        <button className="animate-float bg-indigo-400 px-10 py-3 text-xl uppercase mt-10 rounded-lg">{content.header.btnText}</button>
                    </Link>
                </div>
            </div>
            <Switch>
                <Route path="/contact">
                    <Contact />
                </Route>
            </Switch >
        </div >
    );
}

export default Home;