import React from "react";
import emailkey from '../emailkey'
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: ''
        }
    }

    onNameChange(event) {
        this.setState({ name: event.target.value })
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    onSubjectChange(event) {
        this.setState({ subject: event.target.value })
    }

    onMessageChange(event) {
        this.setState({ message: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.name === '' ||
            this.state.email === '' ||
            this.state.subject === '' ||
            this.state.message === '') {
            alert("Form fields cannot be empty. Please try again");
            this.setState({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } else {
            emailjs.sendForm(emailkey.SERVICE_ID, emailkey.TEMPLATE_ID, event.target, emailkey.USER_ID)
                .then((result) => {
                    alert("Message Sent, I will get back to you shortly.", result.text);
                    this.setState({
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    });
                },
                    (error) => {
                        alert("An error occurred. Please try again.", error.text);
                    })
                .catch((err) => {
                    console.log('FAILED...', err);
                });
        }

        console.log(this.state);
    }

    handleClickEmail(event) {
        window.location = "mailto:s.demir.eu@gmail.com";
        event.preventDefault();
    }

    render() {
        return (
            <div className="relative flex flex-row flex-wrap bg-gray-100 text-gray-900 body-font justify-center mx-auto my-8 max-w-7xl rounded-lg shadow-2xl h-auto lg:h-full font-philosopher" id="serkan-demir-contact">
                <div className="flex flex-col w-1/2 justify-center items-left order-first mx-auto">
                    <h2 className="text-4xl lg:text-5xl font-bold leading-tight mt-8">Lets talk about everything!</h2>
                    <div className="text-gray-700 mt-8">
                        Hate forms? Send me an <Link to='#' onClick={this.handleClickEmail.bind(this)}><span className="underline">email</span></Link> instead.
                    </div>
                </div>
                <div className="w-1/3 my-8 order-last mx-auto">
                    <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <div className="form-group">
                            <label className="uppercase text-sm text-gray-600 font-bold" htmlFor="name">Name</label>
                            <input type="text" className="form-control w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" value={this.state.name} name="name" onChange={this.onNameChange.bind(this)} />
                        </div>
                        <div className="form-group mt-8">
                            <label className="uppercase text-sm text-gray-600 font-bold" htmlFor="email">Email address</label>
                            <input type="email" className="form-control w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" value={this.state.email} name="email" onChange={this.onEmailChange.bind(this)} />
                        </div>
                        <div className="form-group mt-8">
                            <label className="uppercase text-sm text-gray-600 font-bold" htmlFor="subject">Subject</label>
                            <input type="text" className="form-control w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" value={this.state.subject} name="subject" onChange={this.onSubjectChange.bind(this)} />
                        </div>
                        <div className="form-group mt-8">
                            <label className="uppercase text-sm text-gray-600 font-bold" htmlFor="message">Message</label>
                            <textarea className="form-control w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline" rows="5" value={this.state.message} name="message" onChange={this.onMessageChange.bind(this)} />
                        </div>
                        <button type="submit" className="btn btn-primary uppercase text-sm font-bold tracking-wide bg-indigo-400 hover:bg-indigo-600 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline mt-8">Send Message</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Contact;